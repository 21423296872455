.center-item {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
  text-align: center;
  background-color: white;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 4em;
  padding-top: 4em;
  padding-left: 4em;
  padding-right: 4em;
  /* height: 100vh; */
}

body {
  margin: 0;
}

.main-div {
height: 100vh;
width: 100vw;
margin: 0 auto;
background-color: #c0c0c0;
}

.center-item > * {
  margin-bottom: 1.5em;
}

.event-time {
font-size: 3em;
background-color: #87d7ff;
}

.current-event {
  font-size: 4em;
  margin-bottom: 0;
}

.current-round {
  margin-top: 0.5em;
  font-size: 3.25em;
}

.container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.current-time {
font-size: 3em;
background-color: #adffb1;
}

.current-group {
font-size: 3em;
background-color: #ffdead;
}

.notes p {
font-size: 2em;
}

table.schedule-table {
background-color: white;
}

.schedule-table th, .schedule-table td {
padding: 1em;
font-size: 1.2em;
/* background-color: red; */
}

.schedule-table tr:nth-child(even) {
 background-color: #c0c0c0;
}
.schedule-table tr:nth-child(odd) {
 background-color: lightblue;
}

.width-90 {
width: 90%;
display: inline-block;
}

.button-separator {
display: inline-flex;
width: 75%;
text-align: center;
}

.button-separator > a {
margin: 0 auto;
}
.center-button {
justify-content: center;
width: 100%;
display: flex;
}

.notes-field {
width: 100%;
}
